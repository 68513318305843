import * as React from 'react'

export const InfoIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 330'>
    <defs />
    <path d='M165 0C74.019 0 0 74.02 0 165.001 0 255.982 74.019 330 165 330s165-74.018 165-164.999S255.981 0 165 0zm0 300c-74.44 0-135-60.56-135-134.999S90.56 30 165 30s135 60.562 135 135.001C300 239.44 239.439 300 165 300z' />
    <path d='M164.998 70c-11.026 0-19.996 8.976-19.996 20.009 0 11.023 8.97 19.991 19.996 19.991 11.026 0 19.996-8.968 19.996-19.991 0-11.033-8.97-20.009-19.996-20.009zM165 140c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15v-90c0-8.284-6.716-15-15-15z' />
  </svg>
)

export const SuccessIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
    <defs />
    <path d='M437.016 74.984c-99.979-99.979-262.075-99.979-362.033.002-99.978 99.978-99.978 262.073.004 362.031 99.954 99.978 262.05 99.978 362.029-.002 99.979-99.956 99.979-262.051 0-362.031zm-30.168 331.86c-83.318 83.318-218.396 83.318-301.691.004-83.318-83.299-83.318-218.377-.002-301.693 83.297-83.317 218.375-83.317 301.691 0s83.316 218.394.002 301.689z' />
    <path d='M368.911 155.586L234.663 289.834l-70.248-70.248c-8.331-8.331-21.839-8.331-30.17 0s-8.331 21.839 0 30.17l85.333 85.333c8.331 8.331 21.839 8.331 30.17 0l149.333-149.333c8.331-8.331 8.331-21.839 0-30.17s-21.839-8.331-30.17 0z' />
  </svg>
)

export const WarningIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 486.463 486.463'>
    <defs />
    <path d='M243.225 333.382c-13.6 0-25 11.4-25 25s11.4 25 25 25c13.1 0 25-11.4 24.4-24.4.6-14.3-10.7-25.6-24.4-25.6z' />
    <path d='M474.625 421.982c15.7-27.1 15.8-59.4.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4 16.3-74.9 43.4l-156.8 271.5c-15.6 27.3-15.5 59.8.3 86.9 15.6 26.8 43.5 42.9 74.7 42.9h312.8c31.3 0 59.4-16.3 75.2-43.6zm-34-19.6c-8.7 15-24.1 23.9-41.3 23.9h-312.8c-17 0-32.3-8.7-40.8-23.4-8.6-14.9-8.7-32.7-.1-47.7l156.8-271.4c8.5-14.9 23.7-23.7 40.9-23.7 17.1 0 32.4 8.9 40.9 23.8l156.7 271.4c8.4 14.6 8.3 32.2-.3 47.1z' />
    <path d='M237.025 157.882c-11.9 3.4-19.3 14.2-19.3 27.3.6 7.9 1.1 15.9 1.7 23.8 1.7 30.1 3.4 59.6 5.1 89.7.6 10.2 8.5 17.6 18.7 17.6s18.2-7.9 18.7-18.2c0-6.2 0-11.9.6-18.2 1.1-19.3 2.3-38.6 3.4-57.9.6-12.5 1.7-25 2.3-37.5 0-4.5-.6-8.5-2.3-12.5-5.1-11.2-17-16.9-28.9-14.1z' />
  </svg>
)

export const StopIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'>
    <defs />
    <path d='M23.898 4.101A13.904 13.904 0 0014 0a13.905 13.905 0 00-9.899 4.101C1.456 6.745 0 10.261 0 14s1.456 7.255 4.101 9.898A13.904 13.904 0 0014 28a13.9 13.9 0 009.898-4.102C26.544 21.255 28 17.739 28 14s-1.456-7.255-4.102-9.899zM3 14c0-2.938 1.144-5.701 3.222-7.778A10.933 10.933 0 0114 3c2.432 0 4.734.794 6.639 2.241L5.241 20.639A10.915 10.915 0 013 14zm18.777 7.779A10.925 10.925 0 0114 25.001c-2.432 0-4.735-.794-6.638-2.241L22.758 7.364a10.912 10.912 0 012.241 6.638 10.922 10.922 0 01-3.222 7.777z' />
  </svg>
)

export const SharksIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    viewBox='0 0 640 480'
    width='640'
    height='480'
    id='svg3673'
  >
    <defs id='defs3675'>
      <clipPath id='clipPath2624'>
        <path
          d='M 49.5,39.144 L 175.5,39.144 L 175.5,152.456 L 49.5,152.456 L 49.5,39.144 z'
          id='path2626'
        />
      </clipPath>
      <clipPath id='clipPath2616'>
        <path d='M 0,0 L 360,0 L 360,576 L 0,576 L 0,0 z' id='path2618' />
      </clipPath>
      <clipPath id='clipPath2680'>
        <path
          d='M 56.17101,48.83099 L 168.82901,48.83099 L 168.82901,142.76799 L 56.17101,142.76799 L 56.17101,48.83099 z'
          id='path2682'
        />
      </clipPath>
      <clipPath id='clipPath2672'>
        <path
          d='M 49.5,39.144 L 175.5,39.144 L 175.5,152.456 L 49.5,152.456 L 49.5,39.144 z'
          id='path2674'
        />
      </clipPath>
      <clipPath id='clipPath2664'>
        <path d='M 0,0 L 360,0 L 360,576 L 0,576 L 0,0 z' id='path2666' />
      </clipPath>
      <clipPath id='clipPath2704'>
        <path
          d='M 49.5,39.144 L 175.5,39.144 L 175.5,152.456 L 49.5,152.456 L 49.5,39.144 z'
          id='path2706'
        />
      </clipPath>
      <clipPath id='clipPath2696'>
        <path d='M 0,0 L 360,0 L 360,576 L 0,576 L 0,0 z' id='path2698' />
      </clipPath>
    </defs>
    <g id='layer1'>
      <g transform='matrix(5.0080649,0,0,-4.8913549,-243.29391,705.27807)' id='g2612'>
        <g clipPath='url(#clipPath2616)' id='g2614'>
          <g id='g2620'>
            <g clipPath='url(#clipPath2624)' id='g2622'>
              <path
                d='M 129.604,64.28799 C 129.405,64.589 128.929,64.73599 128.73801,65.259 C 128.64999,65.495 128.59399,65.79201 128.397,65.894 C 128.397,65.894 127.621,64.705 127.99001,62.935 C 128.782,62.741 129.58299,63.554 129.58299,63.554 C 129.58299,63.554 129.743,64.078 129.604,64.28799 M 124.83501,65.856 C 123.873,65.48199 123.56599,64.77299 123.56599,64.77299 C 123.56599,64.77299 123.76199,64.621 123.948,64.34801 C 124.134,64.075 124.18201,63.688 124.194,63.567 C 124.20599,63.44701 125.226,63.58701 125.226,63.58701 C 125.51401,63.659 126.308,65.797 126.308,65.797 C 126.308,65.797 126.476,66.274 126.39799,66.37601 C 126.319,66.479 125.799,66.23199 124.83501,65.856'
                id='path2628'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 152.159,120.411 L 146.561,119.295 C 146.33701,119.25 123.951,114.839 109.839,114.839 C 95.901,114.839 73.946,119.114 73.01801,119.29601 L 65.907,120.65199 L 109.791,49.743 L 153.72501,120.769 L 152.159,120.411 z'
                id='path2630'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 146.884,117.504 C 145.95599,117.32001 123.994,113.015 109.839,113.015 C 95.741,113.015 73.63499,117.32001 72.701,117.50301 L 69.577,118.12199 L 109.791,54.026 L 150.005,118.12601 L 146.884,117.504 z'
                id='path2632'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 146.981,116.96001 L 146.981,116.96001 C 146.052,116.776 124.05901,112.461 109.839,112.461 C 95.692,112.461 73.532,116.77699 72.59599,116.96001 L 70.702,117.336 L 109.791,55.032 L 148.88,117.339 L 146.981,116.96001 z'
                id='path2634'
                style={{
                  fill: '#05535d',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 145.729,115.371 C 142.009,114.67599 122.79401,111.209 109.839,111.209 C 96.88901,111.209 77.72501,114.769 74.01401,115.48199 L 73.556,115.57001 L 109.79201,57.854 L 146.186,115.45599 L 145.729,115.371 z'
                id='path2636'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 68.17799,119.132 L 83.966,93.953 C 83.966,93.953 82.80299,104.19501 88.36099,106.76199 C 93.91901,109.33 100.589,114.349 100.589,114.349 L 68.17799,119.132 z'
                id='path2638'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 142.897,104.28799 C 136.98399,101.752 135.58701,100.93201 135.173,100.373 C 135.173,100.373 135.161,100.354 135.155,100.347 C 130.862,104.10201 126.11301,107.713 121.701,110.56 C 121.701,110.56 124.589,110.673 124.02699,111.032 C 123.367,112.44 122.50999,113.931 121.49001,115.528 C 119.789,115.52901 113.36501,114.839 109.839,114.839 C 108.17999,114.839 106.407,114.89999 104.571,115.007 C 104.71899,114.66499 104.84801,114.33501 104.952,114.015 C 103.853,113.92999 101.06799,113.291 100.289,113.187 C 99.37,113.064 97.797,112.715 96.586,112.354 C 86.326,110.16499 82.713,105.334 82.55099,105.058 L 82.714,104.73199 L 82.802,104.207 C 82.79401,104.131 84.271,104.069 84.271,103.097 C 84.271,100.832 85.60201,95.961 87.49699,91.327 C 86.43201,91.231 93.739,77.425 92.539,77.45599 L 93.43201,76.17599 C 100.203,74.43401 102.57899,74.19 108.868,74.32401 C 108.868,74.32401 109.21201,74.33099 109.55499,74.338 C 111.17999,73.27499 113.013,72.076 114.80299,70.99001 C 115.879,70.33701 117.298,69.424 118.80099,68.457 C 119.437,68.048 121.317,68.89799 121.90601,68.52299 C 121.90601,68.52299 143.629,103.44501 143.767,104.67 C 143.767,104.67 143.175,104.407 142.897,104.28799'
                id='path2640'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 84.731,92.32001 C 84.85001,92 85.147,91.50101 85.37801,90.951 C 87.241,90.951 88.595,91.274 89.785,91.31599 C 89.785,91.31599 94.99899,85.89999 95.105,87.87199 C 95.211,89.841 91.88,93.86501 88.894,93.42101 C 85.907,92.976 84.321,93.745 84.731,92.32001'
                id='path2642'
                style={{
                  fill: '#05535d',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 152.886,75.472 C 151.618,78.30299 151.429,78.869 151.429,78.869 C 151.429,78.869 150.35001,83.787 149.47701,84.869 C 148.605,85.952 147.09801,86.617 147.09801,86.617 C 147.09801,86.617 146.931,86.836 146.629,87.218 C 147.96001,89.564 150.16901,93.745 152.084,98.73 C 155.058,106.47301 156.30701,109.78101 155.297,109.407 C 154.285,109.032 147.388,105.411 143.164,103.601 C 138.94299,101.78999 136.147,100.478 135.73199,99.91701 C 135.547,99.66701 135.42101,99.45599 135.285,99.269 C 131.26199,102.836 126.343,106.692 121.33099,109.927 C 114.75,114.172 113.879,114.588 110.785,114.838 C 107.69299,115.088 102.226,114.75999 99.589,114.408 C 98.588,114.274 97.492,114.002 96.46899,113.69701 C 86.36099,111.55701 82.55099,105.058 82.55099,105.058 C 82.55099,105.058 81.946,98.94099 84.731,92.32001 C 86.59399,92.32001 88.062,92.61099 89.25101,92.653 C 95.67101,88.789 94.80701,87.063 92.82201,85.98801 C 95.82001,83.786 100.80499,79.685 101.735,79.371 C 103.222,78.86501 104.683,78.20399 105.40601,77.815 C 107.367,76.75301 111.351,73.944 115.157,71.633 C 118.963,69.323 126.87,63.88901 128.713,63.577 C 130.556,63.265 133.17799,62.814 134.605,62.877 C 136.03,62.938 136.883,63.285 136.883,63.285 C 136.883,63.285 138.81599,62.702 140.718,62.702 C 142.62199,62.702 143.99699,63.45399 143.99699,63.45399 C 143.99699,63.45399 145.424,62.58 148.51601,62.33 C 148.51601,62.33 150.54201,62.161 152.44501,62.185 C 154.41,62.14 155.839,62.485 156.06,62.761 C 156.42101,63.21201 154.155,72.642 152.886,75.472'
                id='path2644'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 137.451,64.64301 C 135.94099,64.642 133.229,64.64 132.13499,64.84 L 131.657,64.92599 L 132.076,65.185 C 132.7,65.573 133.418,66.033 133.877,66.405 C 132.10699,66.33 129.239,66.425 128.379,66.651 L 128.062,66.73399 L 128.302,66.96899 C 128.533,67.196 128.88,67.479 129.24699,67.77699 C 129.64101,68.09801 130.063,68.446 130.35899,68.73801 C 129.06799,68.59599 127.117,68.431 126.158,68.47 L 125.76601,68.485 L 126.103,68.86099 C 126.39,69.17999 126.929,69.783 127.187,70.27901 C 127.173,70.276 127.133,70.269 127.133,70.269 C 126.088,70.084 124.657,69.82899 124.05499,69.85201 L 123.72501,69.864 L 123.914,70.147 C 124.18401,70.545 124.45799,70.96201 124.64,71.27499 C 124.507,71.24899 124.37,71.22099 124.37,71.22099 C 123.61501,71.058 122.677,70.853 122.17101,71.017 L 121.931,71.09399 L 122.08,71.306 C 122.368,71.718 122.487,72.00101 122.54401,72.19099 C 121.989,72.045 121.09599,71.868 120.59399,72.084 L 120.347,72.189 L 120.536,72.38901 C 120.679,72.539 120.80701,72.784 120.882,72.989 C 118.744,73.00999 117.51601,72.632 117.51601,72.632 C 120.84599,72.507 124.215,67.34599 125.088,66.38901 C 125.96001,65.43201 128.576,64.18201 131.867,63.808 C 134.37,63.52299 136.12399,63.86 136.62801,63.978 C 136.896,64.158 137.17,64.38 137.451,64.64301'
                id='path2646'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 134.72099,67.97099 C 131.114,69.92599 128.933,71.716 124.573,72.59 C 123.323,72.841 122.20799,72.951 121.256,72.981 C 121.19299,72.77 121.086,72.549 120.972,72.371 C 121.42999,72.315 122.14301,72.45399 122.726,72.63 L 122.95399,72.69701 L 122.95399,72.44901 C 122.95399,72.27901 122.87601,71.912 122.52901,71.34399 C 123.00101,71.313 123.73,71.465 124.297,71.589 L 125.276,71.783 L 125.142,71.459 C 125.041,71.213 124.774,70.787 124.41,70.237 C 125.10001,70.289 126.25999,70.494 127.073,70.638 L 127.729,70.754 L 127.653,70.47 C 127.515,69.951 126.974,69.298 126.573,68.841 C 127.636,68.84801 129.446,69.00301 130.78999,69.164 L 131.179,69.21001 L 130.957,68.87199 C 130.716,68.5 130.08099,67.98199 129.466,67.48199 C 129.23,67.289 129.017,67.112 128.826,66.94701 C 130.006,66.772 132.79401,66.69 134.35699,66.80299 L 134.78999,66.83299 L 134.504,66.491 C 134.201,66.13 133.466,65.627 132.701,65.14301 C 133.97701,65.015 136.231,65.017 137.522,65.01801 L 138.33701,65.019 L 138.03101,64.69901 C 137.89301,64.552 137.59,64.239 137.192,63.927 C 137.786,63.802 138.88699,63.588 139.55701,63.558 C 140.666,63.511 141.97501,63.392 143.36301,63.724 C 141.42,64.63901 138.328,66.01401 134.72099,67.97099'
                id='path2648'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 106.019,102.31 C 106.019,102.31 108.08,100.728 110.22099,97.815 C 112.36301,94.901 113.789,92.653 113.39301,92.403 C 112.99699,92.153 111.411,92.903 111.411,92.903 C 111.411,92.903 114.85899,90.661 118.03101,87.748 C 121.201,84.83299 122.827,83.16299 122.827,83.16299 C 122.827,83.16299 127.98199,83.24699 128.536,81.914 C 129.092,80.582 129.33,80 129.726,79.25 C 130.12199,78.50101 132.58099,74.755 132.897,74.755 C 133.215,74.755 134.174,74.75999 134.412,75.00999 C 134.64999,75.261 135.03799,76.08701 135.03799,76.08701 C 135.03799,76.08701 135.356,74.255 134.72099,73.34 C 135.347,72.83701 141.22701,68.866 146.343,66.604 C 150.79601,64.63699 154.791,63.041 155.187,63.45799 C 155.584,63.87399 153.43201,72.175 152.164,75.005 C 150.895,77.83501 150.70599,78.40199 150.70599,78.40199 C 150.70599,78.40199 149.627,83.32001 148.754,84.40199 C 147.883,85.485 146.37601,86.151 146.37601,86.151 C 146.37601,86.151 142.492,91.23801 138.05099,95.567 C 133.612,99.896 127.188,105.214 120.60899,109.46001 C 114.02699,113.70599 113.15601,114.12199 110.063,114.371 C 106.97099,114.621 102.056,114.131 99.44,113.632 C 96.823,113.132 93.33299,111.80099 93.33299,111.80099 C 93.33299,111.80099 96.90199,110.052 100.62801,106.972 L 106.019,102.31 z'
                id='path2650'
                style={{
                  fill: '#05535d',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 126.039,80.20799 C 126.039,80.20799 127.42599,78.87601 128.259,77.62801 C 129.092,76.37801 130.479,74.089 130.479,74.089 C 130.479,74.089 128.61501,74.963 128.101,75.255 C 127.586,75.547 127.14999,75.879 127.14999,75.879 C 127.14999,75.879 126.55499,77.29401 126.396,78.168 C 126.237,79.043 126.039,80.20799 126.039,80.20799'
                id='path2652'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 116.215,84.02499 L 117.157,82.66299 C 117.157,82.66299 116.12601,78.26601 116.25,76.955 C 116.25,76.955 115.49001,80.105 116.215,84.02499'
                id='path2654'
                style={{
                  fill: '#05535d',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 113.914,86.26199 L 114.855,84.698 C 114.855,84.698 113.991,79.82899 114.209,78.17599 C 114.209,78.17599 113.19099,81.76401 113.914,86.26199'
                id='path2656'
                style={{
                  fill: '#05535d',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 111.63699,88.297 L 112.578,86.935 C 112.578,86.935 111.83501,81.83701 112.054,79.651 C 112.054,79.651 110.89101,83.49001 111.63699,88.297'
                id='path2658'
                style={{
                  fill: '#05535d',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
            </g>
          </g>
        </g>
      </g>
      <g transform='matrix(5.0080649,0,0,-4.8913549,-243.29391,705.27807)' id='g2660'>
        <g clipPath='url(#clipPath2664)' id='g2662'>
          <g id='g2668'>
            <g clipPath='url(#clipPath2672)' id='g2670'>
              <g id='g2676'>
                <g clipPath='url(#clipPath2680)' id='g2678'>
                  <path
                    d='M 106.455,96.233 C 106.455,96.233 98.24899,101.645 92.858,103.18401 C 87.466,104.724 86.991,104.933 86.673,104.68201 C 86.356,104.433 85.44501,103.72501 85.44501,103.72501 C 85.44501,103.72501 86.237,105.39 86.634,105.39 C 87.03101,105.39 90.36099,104.80701 95.078,102.89301 C 99.70599,101.01401 103.60201,98.27299 106.455,96.233'
                    id='path2684'
                    style={{
                      fill: '#05535d',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                  />
                  <path
                    d='M 89.231,92.05 C 89.231,92.05 84.293,91.51601 75.13499,91.14101 C 65.97701,90.76601 56.218,90.681 56.173,90.49001 C 55.995,89.741 67.97701,83.98399 82.44099,79.966 C 98.054,75.63 100.112,74.88 108.854,75.067 C 107.96201,76.37801 106.952,77.439 105.22701,78.87601 C 103.502,80.312 95.118,88.80299 89.231,92.05'
                    id='path2686'
                    style={{
                      fill: '#010101',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                  />
                  <path
                    d='M 104.216,102.039 C 104.216,102.039 108.91299,102.914 114.26601,101.85201 C 119.618,100.791 128.81,97.351 129.356,97.71001 C 130.308,98.33299 122.41499,114.317 115.22701,123.364 C 108.813,131.437 99.578,139.64 92.74001,142.761 C 91.998,143.10001 98.508,131.46001 101.183,123.78101 C 103.85899,116.101 103.41299,112.216 103.41299,112.216 C 103.41299,112.216 97.021,112.591 92.502,111.467 C 87.98199,110.343 95.29601,104.10001 104.216,102.039'
                    id='path2688'
                    style={{
                      fill: '#010101',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                  />
                  <path
                    d='M 128.924,68.77699 C 128.90199,68.80299 128.457,69.185 128.183,69.595 C 128.16901,69.85699 128.118,70.42599 127.911,70.619 C 127.869,70.87601 127.489,70.968 127.056,70.963 C 126.30901,70.95399 126.11,71.201 125.05099,71.02499 C 123.99001,70.84801 121.793,70.371 116.011,70.392 C 116.011,70.392 108.146,70.349 104.21001,70.638 C 100.274,70.92799 98.53999,71.24001 98.22701,71.028 C 97.91499,70.82001 92.06,66.298 88.67101,62.711 C 85.285,59.123 82.52901,55.41901 80.85699,53.293 C 79.186,51.17101 78.489,49.925 78.53101,49.75999 C 78.55299,49.675 78.957,49.03101 79.00999,48.894 C 79.092,48.688 82.155,49.05 84.632,49.118 C 87.10899,49.185 90.37399,49.21899 90.49001,49.386 C 90.60699,49.552 92.33299,54.11301 95.755,57.99001 C 99.181,61.867 102.998,65.70599 103.39,65.668 C 103.785,65.632 113.22,65.939 117.62199,66.39799 C 122.021,66.85699 127.758,67.49699 128.10899,67.74699 C 128.46001,67.99899 129.202,68.45 128.924,68.77699'
                    id='path2690'
                    style={{
                      fill: '#010101',
                      fillOpacity: 1,
                      fillRule: 'nonzero',
                      stroke: 'none',
                    }}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g transform='matrix(5.0080649,0,0,-4.8913549,-243.29391,705.27807)' id='g2692'>
        <g clipPath='url(#clipPath2696)' id='g2694'>
          <g id='g2700'>
            <g clipPath='url(#clipPath2704)' id='g2702'>
              <path
                d='M 95.211,64.32401 C 93.65199,64.425 92.44,64.46899 91.50301,64.48 C 90.636,63.685 89.804,62.87801 89.082,62.11501 C 88.918,61.94 88.755,61.765 88.59399,61.591 C 90.94299,61.868 94.929,62.22701 97.50101,61.855 C 97.963,62.37399 98.43201,62.892 98.89301,63.392 C 98.189,63.821 97.052,64.203 95.211,64.32401'
                id='path2708'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 88.209,61.17101 C 87.433,60.32001 86.698,59.472 86.005,58.64999 C 86.98,58.74899 88.394,58.838 90.513,58.90601 C 92.323,58.96201 93.718,58.85001 94.66,58.58 C 94.782,58.73599 94.89301,58.869 94.98801,58.97701 L 95.032,59.02699 C 95.67101,59.771 96.423,60.638 97.205,61.52299 C 94.692,61.81799 90.787,61.49001 88.209,61.17101'
                id='path2710'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 90.524,58.533 C 88.11301,58.457 86.62399,58.351 85.658,58.23599 C 85.408,57.93201 85.164,57.63699 84.925,57.34399 C 85.632,57.468 86.60201,57.61301 87.914,57.761 L 88.339,57.811 C 90.724,58.08099 93.004,58.341 94.287,58.088 C 94.33299,58.151 94.37601,58.21201 94.42101,58.269 C 93.657,58.463 92.444,58.59399 90.524,58.533'
                id='path2712'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 87.045,53.564 C 89.2,53.662 90.142,53.705 91.328,53.43401 C 91.66,54.034 92.005,54.621 92.34801,55.18201 C 91.593,55.49899 90.492,55.77499 88.931,55.791 C 86.923,55.811 85.101,55.78 83.614,55.70399 C 83.10201,55.056 82.627,54.444 82.194,53.883 L 81.53,53.033 C 82.819,53.243 84.55499,53.451 86.39301,53.534 L 87.045,53.564 z'
                id='path2714'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 88.37601,57.44 L 87.952,57.392 C 86.367,57.21201 85.28101,57.036 84.565,56.89799 C 84.34399,56.62399 84.12801,56.353 83.918,56.089 C 85.351,56.155 87.061,56.183 88.933,56.162 C 90.59,56.145 91.75,55.85001 92.547,55.505 C 93.08,56.36099 93.601,57.136 94.048,57.761 C 92.77901,57.94299 90.472,57.67999 88.37601,57.44'
                id='path2716'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 92.05499,64.983 C 92.005,64.937 91.953,64.89301 91.903,64.845 C 92.784,64.828 93.87601,64.782 95.233,64.69501 C 96.95399,64.58099 98.26401,64.237 99.149,63.67 C 99.25,63.77699 99.34801,63.884 99.448,63.99001 C 99.19299,64.11301 98.91499,64.22701 98.627,64.328 C 97.604,64.68401 93.823,64.899 92.05499,64.983'
                id='path2718'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 94.948,67.485 C 94.158,66.826 93.299,66.093 92.44901,65.33701 C 94.353,65.242 97.73599,65.02901 98.73801,64.681 C 99.06599,64.567 99.39999,64.42799 99.71201,64.27 C 100.521,65.12199 101.267,65.868 101.862,66.38499 C 101.25301,66.724 100.246,67.04201 98.59399,67.25 C 97.09599,67.44 95.90199,67.5 94.948,67.485'
                id='path2720'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 92.72,55.10699 C 92.748,54.936 92.77699,54.765 92.804,54.60201 C 93.255,55.323 93.72501,56.007 94.21001,56.655 C 94.18201,56.832 94.14999,57.032 94.116,57.23599 C 93.688,56.62601 93.20799,55.89799 92.72,55.10699'
                id='path2722'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 94.93401,57.576 C 95.12,57.80099 95.306,58.02699 95.496,58.242 C 95.52,58.269 96.28101,59.129 97.326,60.265 C 97.302,60.465 97.26601,60.72099 97.22501,60.998 C 96.612,60.302 95.966,59.561 95.295,58.77901 L 95.248,58.72501 C 95.127,58.58701 94.981,58.407 94.815,58.19299 C 94.845,58.039 94.88901,57.813 94.93401,57.576'
                id='path2724'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 99.19099,63.174 C 99.24899,62.944 99.314,62.69299 99.375,62.45399 C 99.46899,62.55299 99.564,62.651 99.658,62.75 C 99.627,62.99699 99.591,63.285 99.55499,63.56799 C 99.436,63.44 99.314,63.30901 99.19099,63.174'
                id='path2726'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 101.849,65.899 C 101.328,65.414 100.657,64.73801 99.871,63.903 C 99.90199,63.644 99.94,63.35201 99.974,63.07401 C 100.651,63.769 101.30901,64.42 101.871,64.935 C 101.86501,65.248 101.85699,65.59399 101.849,65.899'
                id='path2728'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 92.521,54.13699 C 92.493,54.308 92.464,54.479 92.438,54.64301 C 92.157,54.17599 91.87601,53.69 91.606,53.201 C 91.633,53.026 91.659,52.84399 91.685,52.66901 C 91.95599,53.172 92.235,53.662 92.521,54.13699'
                id='path2730'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 98.90401,62.862 C 98.47099,62.39101 98.01401,61.884 97.535,61.34599 C 97.57001,61.097 97.60699,60.838 97.63901,60.606 C 98.092,61.095 98.58299,61.623 99.086,62.151 C 99.021,62.405 98.957,62.653 98.90401,62.862'
                id='path2732'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 94.52699,57.811 C 94.48801,57.757 94.45,57.702 94.41,57.647 C 94.44099,57.44501 94.47501,57.239 94.508,57.03999 C 94.55299,57.09801 94.595,57.158 94.64301,57.216 C 94.601,57.429 94.561,57.63901 94.52699,57.811'
                id='path2734'
                style={{
                  fill: '#ffffff',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 128.063,68.89 C 127.85899,68.989 127.552,69.14 127.504,69.55901 L 127.491,69.819 C 127.48199,70.246 127.45599,70.259 127.22099,70.257 C 126.909,70.254 126.67599,70.29201 126.472,70.325 C 126.14799,70.37801 125.869,70.424 125.265,70.323 L 124.97701,70.27299 C 123.84801,70.077 121.498,69.666 116.172,69.686 C 116.093,69.686 108.24699,69.647 104.34399,69.93401 L 100.774,70.243 C 99.64301,70.351 98.752,70.435 98.561,70.371 C 98.38901,70.24899 97.08299,69.24899 95.397,67.856 C 96.285,67.85001 97.351,67.783 98.636,67.62 C 100.241,67.41701 101.41701,67.088 102.159,66.63499 C 102.563,66.96201 102.871,67.146 103.037,67.131 C 103.616,67.089 113.069,67.243 117.397,67.696 L 125.106,68.47701 C 126.532,68.616 126.914,68.65401 127.01601,68.69501 L 127.04601,68.70799 L 127.077,68.70799 C 127.37199,68.71001 127.847,68.73801 128.257,68.77499 C 128.19901,68.817 128.136,68.854 128.063,68.89'
                id='path2736'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 87.06,53.192 L 86.409,53.16299 C 84.327,53.07001 82.45,52.81799 81.192,52.599 C 80.089,51.188 79.601,50.41 79.38699,49.998 C 80.02299,50.028 81.022,50.089 81.88699,50.142 L 84.696,50.29401 C 86.269,50.34 89.24899,50.435 89.88901,50.502 C 90.23801,51.341 90.673,52.226 91.145,53.097 C 90.062,53.33 89.147,53.289 87.06,53.192'
                id='path2738'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 168.69099,103.97099 C 168.82899,105.022 168.82899,106.013 168.82899,106.013 C 168.82899,106.013 167.119,106.48199 166.10201,106.509 C 165.08501,106.534 163.37601,106.261 163.37601,106.261 C 163.37601,106.261 162.422,104.83099 162.198,104.216 C 162.138,104.05901 162.451,102.536 162.451,102.536 C 162.451,102.536 160.987,99.797 159.974,97.765 C 158.963,95.733 157.30299,92.56599 154.948,88.724 C 152.82401,85.25999 151.231,82.64301 151.231,82.64301 L 151.961,80.869 L 152.647,81.397 L 152.821,81.466 L 153.41499,81.41499 C 153.41499,81.41499 154.498,82.69501 155.892,84.16299 C 157.28799,85.629 160.394,90.72301 162.384,93.62 C 164.371,96.517 166.285,100.808 166.285,100.808 C 166.285,100.808 167.272,100.916 167.83701,101.17999 C 168.40199,101.44501 168.534,102.32201 168.534,102.32201 C 168.534,102.32201 168.57001,103.04401 168.69099,103.97099'
                id='path2740'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 164.89999,104.38699 C 164.89999,104.38699 166.974,104.343 167.112,104.341 C 167.254,104.50301 167.683,105.011 167.965,105.53999 C 167.534,105.593 166.662,105.69099 165.944,105.709 C 165.13,105.73 164.04601,105.44901 163.724,105.35899 C 163.537,105.097 163.20599,104.606 162.992,104.19901 C 163.48199,104.31 164.125,104.399 164.89999,104.38699'
                id='path2742'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 153.054,83.841 L 153.317,84.101 L 153.187,83.703 C 153.187,83.67999 153.15401,83.50999 153.101,83.392 C 153.224,83.42999 153.608,83.633 153.608,83.633 C 153.608,83.633 153.008,82.51601 152.841,82.28 C 152.88699,82.31 153.41499,82.74699 153.461,82.776 C 154.035,83.189 157.179,87.67101 160.03,92.56599 C 161.347,94.83 164.45,100.45 166.164,103.194 C 165.86099,103.23199 165.409,103.258 165.01601,103.28 C 164.375,103.317 164.03,103.17101 163.72301,103.04401 L 163.35201,102.911 C 163.217,102.754 162.873,102.11 162.873,102.11 C 162.862,102.093 161.45,99.407 160.448,97.394 C 159.46899,95.42799 157.489,91.75999 155.55499,88.47501 C 154.289,86.325 152.847,84.23801 151.85001,83.086 C 152.05099,83.263 152.935,83.724 153.054,83.841'
                id='path2744'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 152.785,83.028 C 152.68201,82.996 152.58701,82.96899 152.577,82.966 C 152.27299,82.69901 151.87601,82.612 151.728,82.55 C 151.77699,82.446 152.116,81.728 152.16701,81.617 C 152.245,81.677 152.39301,81.791 152.439,81.826 C 152.409,81.91499 152.37199,82.026 152.33,82.16299 C 152.48399,82.44099 152.65199,82.842 152.785,83.028'
                id='path2746'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 125.226,63.58701 C 125.226,63.58701 124.20599,63.44701 124.194,63.567 C 124.18201,63.688 124.134,64.075 123.948,64.34801 C 123.76199,64.621 123.56599,64.77299 123.56599,64.77299 C 123.56599,64.77299 123.873,65.48199 124.83501,65.856 C 125.799,66.23199 126.319,66.479 126.39799,66.37601 C 126.476,66.274 126.308,65.797 126.308,65.797 C 126.308,65.797 125.51401,63.659 125.226,63.58701'
                id='path2748'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 125.882,65.927 C 125.77699,65.88499 125.66299,65.84 125.55,65.795 L 124.97,65.565 C 124.42799,65.354 124.13901,65.02901 124.00101,64.83299 C 124.07899,64.75301 124.16701,64.651 124.256,64.52 C 124.33099,64.411 124.52299,64.077 124.565,63.95799 C 124.99001,64.549 125.784,65.53 125.882,65.927'
                id='path2750'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 155.58701,78.577 C 155.58701,78.577 156.47501,79.108 156.40401,79.22701 C 156.334,79.349 156.114,79.746 156.076,80.12399 C 156.03999,80.5 156.092,80.772 156.092,80.772 C 156.092,80.772 155.384,81.23801 154.384,80.967 C 153.383,80.694 152.813,80.588 152.82201,80.44099 C 152.828,80.29401 152.95799,79.894 152.95799,79.894 C 152.95799,79.894 155.315,78.45599 155.58701,78.577'
                id='path2752'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 153.522,80.35201 C 153.631,80.384 153.75,80.41299 153.868,80.444 L 154.474,80.604 C 155.034,80.757 155.47501,80.642 155.713,80.54601 C 155.707,80.42 155.705,80.267 155.72301,80.086 C 155.737,79.935 155.774,79.787 155.819,79.64799 C 155.09599,79.92799 154.177,80.24899 153.522,80.35201'
                id='path2754'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 129.604,64.28799 C 129.405,64.589 128.929,64.73599 128.73801,65.259 C 128.64999,65.495 128.59399,65.79201 128.397,65.894 C 128.397,65.894 127.621,64.705 127.99001,62.935 C 128.782,62.741 129.58299,63.554 129.58299,63.554 C 129.58299,63.554 129.743,64.078 129.604,64.28799'
                id='path2756'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 129.092,63.73199 L 129.14101,63.776 L 129.097,63.841 C 128.935,64.091 128.701,64.481 128.504,64.94299 L 128.435,65.101 L 128.39,64.952 C 128.286,64.606 128.175,64.056 128.237,63.395 L 128.246,63.295 L 128.537,63.37601 C 128.713,63.44901 128.89999,63.569 129.092,63.73199'
                id='path2758'
                style={{
                  fill: '#f28f20',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 113.14999,49.68201 L 113.14999,51.69701 L 112.56,51.69701 L 112.56,52.10699 L 114.14,52.10699 L 114.14,51.69701 L 113.55,51.69701 L 113.55,49.68201 L 113.14999,49.68201 z'
                id='path2760'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
              <path
                d='M 114.392,49.68201 L 114.392,52.10699 L 114.993,52.10699 L 115.354,50.451 L 115.709,52.10699 L 116.315,52.10699 L 116.315,49.68201 L 115.94099,49.68201 L 115.94099,51.593 L 115.545,49.68201 L 115.159,49.68201 L 114.767,51.593 L 114.767,49.68201 L 114.392,49.68201 z'
                id='path2762'
                style={{
                  fill: '#010101',
                  fillOpacity: 1,
                  fillRule: 'nonzero',
                  stroke: 'none',
                }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
