import * as React from 'react'

export const Hamburger = () => (
  <svg className='w-6 h-6 fill-current' viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
    />
  </svg>
)
